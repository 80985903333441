const ExperienceContent = [
  {
    job: 'experiencepanel.job1',
    company: 'experiencepanel.company1',
    years: 'experiencepanel.years1'
  },
  {
    job: 'experiencepanel.job2',
    company: 'experiencepanel.company2',
    years: 'experiencepanel.years2'
  },
  {
    job: 'experiencepanel.job3',
    company: 'experiencepanel.company3',
    years: '2013 - 2014'
  },
  {
    job: 'experiencepanel.job4',
    company: 'experiencepanel.company4',
    years: '2011 - 2013'
  },
  {
    job: 'experiencepanel.job5',
    company: 'experiencepanel.company5',
    years: '2008 - 2011'
  },
  
]

export default ExperienceContent