const FocusContent = [
  {
    focus: 'focuspanel.focus1',
    type: ' - Wordpress',
    descripton: 'focuspanel.description1'
  },
  {
    focus: 'focuspanel.focus2',
    type: ' - React, Typescript, .net, nodeJS ....',
    descripton: 'focuspanel.description2'
  },
  {
    focus: 'focuspanel.focus3',
    type: ' - Amazon AWS.',
    descripton: 'focuspanel.description3'
  },
  {
    focus: 'focuspanel.focus4',
    type: '',
    descripton: 'focuspanel.description4'
  },
]

export default FocusContent