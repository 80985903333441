const CustomerContent = [
  {
    image: 'maul',
    category: 'customer.softdev',
    title: 'Maul Reykjavík',
    description: 'customer.description1',
    link: 'https://maul.is'
  },
  {
    image: 'friggz',
    category: 'customer.webdev',
    title: 'Friggz sf.',
    description: 'customer.description3',
    link: 'https://friggz.is'
  },
  {
    image: 'oba',
    category: 'customer.webdev',
    title: 'Ottó B Arnar ehf.',
    description: 'customer.description4',
    link: 'https://oba.is'
  }
]

export default CustomerContent;