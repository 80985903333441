const EducationalContent = [
  {
    diploma: 'educationpanel.diploma4',
    school: 'educationpanel.school4',
    year: ' - 2021'
  },
  {
    diploma: 'educationpanel.diploma1',
    school: 'educationpanel.school1',
    year: ' - 2021'
  },
  {
    diploma: 'educationpanel.diploma2',
    school: 'educationpanel.school2',
    year: ' - 2020'
  },
  {
    diploma: 'educationpanel.diploma3',
    school: 'educationpanel.school3',
    year: ' - 2010'
  },
]

export default EducationalContent;